import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { navigate } from 'gatsby';
import { Form, Select } from 'antd';
import { SectionMainWrapper } from '../../contact.style';
import Button from 'common/src/components/Button';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import { updateUserInfo } from '../../../../actions';
import './buyProcess.css';

const { Option } = Select;
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 2,
    },
  },
};

const DoYouOwnOtherRealEstate = ({
  sectionWrapper,
  row,
  button,
  title,
  currentUser,
  description,
}) => {
  const [form] = Form.useForm();
  const [numberOfRealEstateAssets, setNumberOfRealEstateAssets] = useState(null);
  const [ownOtherRealEstate, setOwnOtherRealEstate] = useState(false);
  
  const handleOwnOtherRealEstate = (e) => {
    console.log('own other real estate', e.target.value);
    setOwnOtherRealEstate(e.target.value);
  };

  const handleNumberOfRealEstateAssets = () => {
	  const numberOfItems = currentUser.assets.realEstate
	  setNumberOfRealEstateAssets(numberOfItems)	  
  }

  const onFinish = async (value) => {
	  console.log("currentUser do you own", currentUser)
	console.log("otherRealEstate loanpurpose", ownOtherRealEstate, currentUser.loan.termsOfLoan.loanPurposeType)
	if(ownOtherRealEstate == "false" && currentUser.loan.termsOfLoan.loanPurposeType == "Refinance") {
		navigate('/buy-pages/assets/');
	}
	else if(ownOtherRealEstate == "false" && currentUser.loan.termsOfLoan.loanPurposeType == "Purchase") {
		navigate('/buy-pages/firstTimeHomebuyer/');
	}

	else{
		//do you own any other real estate page
		navigate('/buy-pages/additionalRealEstateYouOwn/')
	}
  };

  //number of real estate assets
  useEffect(() => {

  })

  return (
    <SectionMainWrapper>
      <Box {...sectionWrapper}>
        <Container className="containerClassBuyProcess">
          <Box {...row}>
            <Form
              //{...formItemLayout}
              form={form}
              layout="vertical"
              name="register"
              onFinish={onFinish}
              scrollToFirstError
              style={{ width: '90%' }}
            >
			
			{currentUser?.coBorrower && (	
              <Heading
                content={'Do you or ' + currentUser.coBorrower.firstName + " own any other real estate than what's listed below?"}
                fontWeight="bold"
                {...title}
              />
			)}

			{!currentUser?.coBorrower && (	
              <Heading
                content={'Do you own any other real estate?'}
                fontWeight="bold"
                {...title}
              />
			)}


              <div className="containerClassReasons">
                <li>
                  <span>
                    <label style={{ fontSize: '19px', cursor: 'pointer' }}>
                      <input
                        type="radio"
                        value="true"
                        name="radio"
                        id="radio1"
                        style={{ visibility: 'hidden' }}
						//BorrowerResidencyBasisType
                        onChange={handleOwnOtherRealEstate}
                      />
                      <span>Yes</span>
                    </label>
                  </span>
                </li>

                <li>
                  <label style={{ fontSize: '19px', cursor: 'pointer' }}>
                    <input
                      type="radio"
                      value="false"
                      name="radio"
                      id="radio2"
                      style={{ visibility: 'hidden' }}
                      onChange={handleOwnOtherRealEstate}
                    />
                    <span>No</span>
                  </label>
                </li>
                <p></p>
              </div>

			 
              <Form.Item {...tailFormItemLayout}>
                <p></p>
                <div className="containerClassBuyProcess">
                  <Button
                    type="primary"
                    title="Next"
                    htmlType="submit"
                    {...button}
                    onClick={onFinish}
                    disabled={!ownOtherRealEstate}
                  ></Button>
                </div>
              </Form.Item>
            </Form>
          </Box>
        </Container>
      </Box>
    </SectionMainWrapper>
  );
};

DoYouOwnOtherRealEstate.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  row: PropTypes.object,
  contactForm: PropTypes.object,
  secHeading: PropTypes.object,
  secText: PropTypes.object,
  button: PropTypes.object,
  note: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  colornote: PropTypes.object,
};

DoYouOwnOtherRealEstate.defaultProps = {
  sectionWrapper: {
    id: 'contact_section',
    as: 'section',
    pt: ['8px', '80px', '80px', '80px'],
    pb: ['0', '80px', '80px', '80px', '80px'],
  },
  secTitleWrapper: {
    mb: ['40px', '40px', '40px'],
    p: ['0 15px', 0, 0, 0, 0],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: `${2}`,
    letterSpacing: '0.15em',
    fontWeight: `${6}`,
    color: 'primary',
    mb: `${3}`,
  },
  secHeading: {
    textAlign: 'center',
    fontSize: [`${6}`, `${8}`],
    fontWeight: '400',
    color: 'headingColor',
    letterSpacing: '-0.025em',
    mb: `${0}`,
  },
  row: {
    flexBox: true,
    justifyContent: 'center',
  },
  contactForm: {
    width: [1, 1, 1, 1 / 2],
  },
  button: {
    type: 'button',
    fontSize: `${20}`,
    fontWeight: '600',
    //borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    height: `${10}`,
  },
  note: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  colornote: {
    fontSize: '16px',
    fontWeight: '500',
    color: 'rgb(106, 82, 253)',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['center', 'center'],
  },
  title: {
    fontSize: ['26px', '34px', '42px', '42px', '42px'],
    fontWeight: '500',
    color: '#000000',
    letterSpacing: '-0.025em',
    mb: ['20px', '25px'],
    lineHeight: '1.31',
  },
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
  label: {
    fontSize: '28px',
  },

  description: {
    /* content:
      "This means your assets and income will be counted together. If you want to remove a co-borrower later, you'll have to start a new application", */
    fontSize: '20px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    /* textAlign: ['center', 'center'], */
  },
};

const mapStateToProps = (state) => ({
	currentUser: state.root.currentUser?.user,
	userToken: state.root.currentUser?.token,
  });
  
const mapDispatchToProps = (dispatch) => {
	return {
	  updateUserInfo: (currentUser, userToken) =>
		dispatch(updateUserInfo(currentUser, userToken)),
	};
  };
  
const DoYouOwnOtherRealEstateRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(DoYouOwnOtherRealEstate);

export default DoYouOwnOtherRealEstateRedux;
